@tailwind base;
@tailwind components;
@tailwind utilities;

/* Poppins font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Almarai font */
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");

html {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
}

/* register  ******************************* */
/* phone input  */
.form-control:focus {
  border-color: #b07f4a !important;
  box-shadow: 0px 0px 10px -1px #b07f4a !important;
}

.react-tel-input {
  direction: ltr !important;
}

/* hero slider ********************************************** */

.splide__pagination__page {
  width: 25px !important;
  height: 25px !important;
  margin-right: 15px !important;
}

.splide__pagination__page.is-active {
  background-color: #b07f4a !important;
}

/* slider arrows  ********************************************/
.splide__arrow--prev {
  width: 50px !important;
  height: 50px !important;
}

.splide__arrow--next {
  width: 50px !important;
  height: 50px !important;
}

.splide__arrow svg {
  fill: #b07f4a !important;
  width: 30px !important;
  height: 30px !important;
}

/* pagination  **********************************************/
.ant-pagination-item {
  /* width: 50px !important; */
  /* height: 50px !important; */
  /* font-size: 22px !important; */
}

.ant-pagination-item-active {
  border-color: #b07f4a !important;
  background-color: #b07f4a !important;
  /* color: white !important; */
}

.ant-pagination-item-active a {
  color: white !important;
  /* font-size: 22px !important; */
}

/* cards slide styles  */

.splide__slide {
  padding: 10px 0 10px 0;
}

/* vidoes  */

.youtube-video iframe {
  border-radius: 20px !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  color: black !important;
  border: none !important;
}

.tns-visually-hidden {
  display: none !important;
  left: 0 !important;
}
